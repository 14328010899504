<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>All Breeder Codes</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Breeder Codes"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="discountCodesTable"
            :items="discountCodes"
            :items-per-page="25"
            :footer-props="footerProps"
            no-data-text="No Breeder Codes Sent Out"
          >
            <template v-slot:item.discount="{ item }">
              {{
                item.discount_code.discount_type == "number"
                  ? "£" + item.discount_code.discount_amount
                  : item.discount_code.discount_amount + "%"
              }}
            </template>

            <template v-slot:item.code_used="{ item }">
              <v-chip v-if="item.claimed_at" label small color="success"
                >Yes</v-chip
              >
              <v-chip v-else label small color="error">No</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="!item.claimed_at"
                    x-small
                    depressed
                    color="orange lighten-4 orange--text"
                    v-on="on"
                  >
                    <v-icon x-small>mdi-email-arrow-right</v-icon>
                  </v-btn>
                </template>
                <span>Resend Code Email</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="!item.claimed_at"
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    class="ml-2"
                    @click="openDeleteCode(item)"
                  >
                    <v-icon x-small>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete Code</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="deleteCodeDialog.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Code</v-card-title>
        <v-card-text>Are you sure you wish to archive ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteCode"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteCodeDialog.loading"
            @click="saveDeleteCode"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deleteCodeDialog: {
        dialog: false,
        code: {},
        loading: false,
      },
      searchTerm: "",
      footerProps: { "items-per-page-options": [5, 10, 25, 50, 100, -1] },
      breadcrumbs: [
        {
          text: "Paw Dog Food",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Breeders",
          disabled: false,
          exact: true,
          to: {
            name: "module-paw-breeders",
            params: { id: this.$route.params.id },
          },
        },
        {
          text: "Breeder Codes",
          disabled: true,
        },
      ],
      discountCodesTable: [
        { text: "Date and Time Sent", value: "formatted_dates.created_at" },
        { text: "Email", value: "email" },
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Customer Discount", value: "discount" },
        {
          text: "Expiry Date",
          value: "discount_code.formatted_dates.expires_at",
        },
        { text: "Code Used", value: "code_used" },
        { text: "Breeder", value: "breeder.customer.full_name" },
        { text: "Breeder Credit", value: "available_credit" },
        { text: "Actions", value: "actions", align: "right", sortable: false },
      ],
    };
  },

  computed: {
    discountCodes() {
      let codes = this.$store.state.paw["codes"];

      if (this.searchTerm !== "") {
        codes = codes.filter((c) => {
          const first_name = c.first_name.toLowerCase();
          const last_name = c.last_name.toLowerCase();
          const email = c.email.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            first_name.includes(searchTerm) ||
            last_name.includes(searchTerm) ||
            email.includes(searchTerm)
          );
        });
      }

      return codes;
    },
  },
  methods: {
    formatDeliveries: function () {
      const subscriptions = this.customer.subscriptions;
      let deliveries = [];

      subscriptions.forEach((s) => {
        s.deliveries.forEach((delivery) => {
          let d = delivery;
          d.formattedId = "";
          d.formattedId = s.identifier.concat(delivery.identifier);
          deliveries.push(d);
        });
      });

      this.deliveries = deliveries;
      this.deliveriesLoading = false;
    },
    getDeliveryStatusLabel: function (value) {
      const status = this.deliveryStatuses.find((s) => s.value === value);

      return status.label;
    },
    getDeliveryStatusColor: function (value) {
      const status = this.deliveryStatuses.find((s) => s.value === value);

      return status.color;
    },
    deleteAddress: function (address) {
      this.deleteAddressDialog.open = true;
      this.deleteAddressDialog.addressLine1 = address.line_1;
      this.deleteAddressDialog.addressId = address.id;
    },

    openDeleteCode(code) {
      this.deleteCodeDialog.dialog = true;
      this.deleteCodeDialog.code = code;
    },

    resetDeleteCode() {
      this.deleteCodeDialog.dialog = false;
      this.deleteCodeDialog.code = {};
      this.deleteCodeDialog.loading = false;
    },

    openResendCode(code) {
      this.resendCodeDialog.dialog = true;
      this.resendCodeDialog.code = code;
    },

    resendCode() {
      this.resendCodeDialog.loading = true;
      this.$store
        .dispatch("paw/resendCode", {
          appId: this.$route.params.id,
          codeId: this.resendCodeDialog.code.id,
        })
        .then(() => {
          this.resetResendCode();
        })
        .catch(() => {
          this.resendCodeDialog.loading = false;
        });
    },

    resetResendCode() {
      this.resendCodeDialog.dialog = false;
      this.resendCodeDialog.code = {};
      this.resendCodeDialog.loading = false;
    },

    saveDeleteCode() {
      this.deleteCodeDialog.loading = true;
      this.$store
        .dispatch("paw/deleteCode", {
          appId: this.$route.params.id,
          codeId: this.deleteCodeDialog.code.id,
          isOnBreederPage: false,
        })
        .then(() => {
          this.resetDeleteCode();
        })
        .catch(() => {
          this.deleteCodeDialog.loading = false;
        });
    },
  },
};
</script>
